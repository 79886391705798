<template>
    <div class="root">
    <div class="head">
      <select @change="limites" v-model="preferencias.registros[tabla]">
        <option>50</option>
        <option>100</option>
        <option>150</option>
        <option>200</option>
        <option>300</option>
        <option>400</option>
        <option>500</option>
      </select>
      Viendo {{numero}} registros de {{preferencias.largos[tabla]}}
      <button @click="mas">Ver {{preferencias.registros[tabla]}} más</button>
    </div>
    <div class="container">
      <Tabla ref="tabla" :key="key" tabla='contactos' tabla_preferencias='contactos' :filtrable="true" />
    </div>
  </div>
</template>

<script>
import Tabla from '@/components/Tabla.vue'
import { mapActions, mapState } from 'vuex'
export default {
    name: 'Contactos',
    data: function (){
        return {
            tabla: 'contactos',
            key: 1,
            numero: null
        }
    },
    components:{
        Tabla
    },
    computed: {
        ...mapState(['preferencias','cache'])
    },
    methods:{
        ...mapActions(['api']),
        limites(){
            this.$refs.tabla.registros = parseInt(this.preferencias.registros[this.tabla])
            this.$refs.tabla.filtrar()
            this.api([{'campo':'REGISTROS','valor':this.preferencias.registros[this.tabla]},{'campo':'tipo','valor':'guardar'},{'campo':'TABLA','valor':this.tabla.toUpperCase()},{'campo':'USUARIO','valor':this.cache.user},'preferencias'])
        },
        async nuevo(){
            await this.api([
                {campo:'tabla',valor:this.tabla},
                'nuevoregistro'
            ])
            this.key++
        },
        mas(){
          this.$refs.tabla.ver_mas()
        }
    }
}
</script>

<style scoped>
.head{
  flex-grow: 0;
  color: white;
  width: 100%;
  background-color: rgb(255, 172, 116);
}
.container{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.container > div{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.root{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}</style>